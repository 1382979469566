import { CountUp } from 'countup.js';

const toggles = document.querySelectorAll('[data-toggle="prices"]');

toggles.forEach((toggle) => {
  toggle.addEventListener('change', () => {
    const target = toggle.dataset.target;
    const targetEl = document.querySelectorAll(target);

    const isChecked = toggle.checked;

    targetEl.forEach((el) => {
      const minValue = el.dataset.minValue;
      const maxValue = el.dataset.maxValue;

      const from = el.innerHTML.replace(',', '.');
      const to = isChecked ? maxValue : minValue;

      const config = {
        startVal: from,
        decimalPlaces: 2,
        separator: '.',
        decimal: ',',
        duration: .5
      };

      const countUp = new CountUp(el, to, config);
      countUp.error ? console.error(countUp.error) : countUp.start();

      const label = el.nextElementSibling;
      const minLabel = label.dataset.minValue;
      const maxLabel = label.dataset.maxValue;
      label.innerHTML = isChecked ? maxLabel : minLabel;

      const link = el.closest('div').querySelector('a[data-min-href]')
      if (link) {
        const minHref = link.dataset.minHref;
        const maxHref = link.dataset.maxHref;
        if (minHref)
          link.setAttribute('href', isChecked ? maxHref : minHref);
      }
    });
  });
});
